$icons-path: '../../../node_modules/@axial-healthcare/axial-branding/fonts/icons/';

@font-face {
  font-family: 'axial-app-icons';
  src: url($icons-path + 'axial-app-icons.eot?63053141');
  src: url($icons-path + 'axial-app-icons.eot?63053141#iefix') format('embedded-opentype'),
       url($icons-path + 'axial-app-icons.woff2?63053141') format('woff2'),
       url($icons-path + 'axial-app-icons.woff?63053141') format('woff'),
       url($icons-path + 'axial-app-icons.ttf?63053141') format('truetype'),
       url($icons-path + 'axial-app-icons.svg?63053141#axial-app-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}